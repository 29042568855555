.year-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  & .ant-card-body {
    max-width: 100%;
  }

  &__table-cell {
    cursor: pointer;
  }
}
