.app {
  height: 100vh;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
}

.content-wrapper {
  flex: auto 1 1;
  background-color: #EBF1F6;
  padding: 24px;
  max-width: 100%;
}
